.copy{color: rgba(255, 255, 255, 0.80);
    text-align: center;

    font-size: 14px;
    margin: auto;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.154px;

}.name{
    color: rgba(255, 255, 255, 0.80);
text-align: center;
font-family: 'MyfontL';
font-size: 12px;

font-weight: 500;
line-height: 150%; /* 18px */
letter-spacing: -0.132px;
}.con{
    padding-top: 150px;
    background-color: black;

}.logo{
    background-image: url( "../../public/img/top1.png");
    width: 136px;
height: 102px;
background-size: cover;background-position: center;
margin: auto;
}.arroword{
    color: rgba(255, 255, 255, 0.80);
text-align: center;
width: 50px;
margin: auto;
font-size: 14px;
font-weight: 700;
line-height: 150%; /* 21px */
letter-spacing: -0.154px; 
cursor: pointer; 
}.barrow {
    width: 17px;
    height: 10px;
    flex-shrink: 0;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none"><path d="M8.5 5.82128e-07C8.74482 5.71426e-07 8.96025 0.0920254 9.14631 0.286299L16.7356 8.39468C16.9119 8.56851 17 8.79346 17 9.0593C17 9.591 16.6181 10 16.1089 10C15.8543 10 15.6388 9.88752 15.4724 9.72393L8.5 2.28016L1.52765 9.72393C1.36118 9.88753 1.13594 10 0.891129 10C0.381912 10 -1.78779e-08 9.591 -4.11191e-08 9.0593C-5.27397e-08 8.79346 0.0881336 8.56851 0.254608 8.39468L7.85369 0.286299C8.02995 0.0920254 8.25518 5.92829e-07 8.5 5.82128e-07Z" fill="white"/></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    margin-bottom: 5px;cursor: pointer; 
}
@media (max-width: 767px) {
.arroword{
    font-size: 10px;
}.barrow{
    width: 13px;height: 7px;
}

}