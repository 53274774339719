.none{
    display: contents;
    width: 100%;height: 100%;
 
}
.cslider-container {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 800px;
margin-left: 155px;
    width: 1550px;
}

.cslider {
    display: flex;   transition: margin-left 0.5s ease-in-out, transform 0.5s ease-in-out; 
    margin-left: 2800px;
    align-items: center;
    height: 800px;
}

.cslider-card {
    flex-shrink: 0;
    margin: 0 10px; /* 여기에서 마진을 10px로 변경 */
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 0; /* 기본적으로 카드 숨기기 */
}

.cslider-card.active {
    width: 416px; /* 중앙 카드 크기 고정 */
    height: 520px;
    opacity: 1; /* 활성화된 카드 보이기 */
    transform: scale(1.0); 
}

/* 이전 및 다음 카드 스타일 */
.cslider-card:not(.active) {
    width: 365px; /* 나머지 카드 크기 */
    height: 455px; 
    opacity: 1; 
}

.cslider-card.prev {
    transform: translateX(-36px); /* 왼쪽으로 이동 */
}
.cslider-prev,
.cslider-next {
    background-color: rgba(185, 37, 37, 0.7); /* 버튼 배경색 */
    border: none; /* 버튼 테두리 제거 */
    padding: 10px 20px; /* 버튼 패딩 */
    cursor: pointer; /* 커서 포인터 */
    color: black;
    position: absolute;
    z-index: 1;
    top: 50%; /* 세로 중앙 정렬 */
    transform: translateY(-50%); /* 세로 중앙 정렬 조정 */
}.cslider-prev{
    left: 5px;
}.cslider-next{
    right: 5px;
}
.cslider-card.next {
    transform: translateX(36px); /* 오른쪽으로 이동 */
}
.cslider-card.end {
    width: 326px;
    height: 406px;
    opacity: 1; 
}

.cslider-card.prev-end {
    transform: translateX(-73px);
}

.cslider-card.next-end {
    transform: translateX(73px);
}

.cslider-card img {
    width: 100%;
    height: 100%;
    object-fit: contain; 
}

.cslider-button {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer; /* 커서 포인터 */
    border: 1px solid black; /* 검은색 윤곽선 */
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    z-index: 1;
    top: 50%; /* 세로 중앙 정렬 */
    transform: translateY(-50%); /* 세로 중앙 정렬 조정 */
    /* 슬라이더 카드보다 위에 위치하게 설정 */
}

.cslider-button.left {
  left: 177px;
}

.cslider-button.right {
    right: 160px; /* 오른쪽 끝에 위치 */
}

.cslider-button svg {
    width: 20px;
    height: 20px;
    fill: black;
}

@media (max-width: 767px) {
    .cmobile-slider-container{
 height: 500px; position: relative;
 padding: 0;        margin-left: 0
    }
    .csliderexcon {
        margin: auto;
        height: 700px;
    }

    .cslider {
      
        padding: 0 ; 
    }


    .cslider-button.left {
        margin-left: -155px;
       width: 20px;height: 20px;
        transform: translateY(-80px);
        background-color: white; 
        border-radius: 50%;
        display: flex;
        justify-content: center;
        position: absolute;
        align-items: center;
      }
      
      .cslider-button.right {
       margin-right: 17px;
          border-radius: 50%;
          width: 20px;height: 20px;
          background-color: white; 
   transform: translateY(-80px);
          display: flex;
          justify-content: center;
          align-items: center;      position: absolute;
          right: 5px;z-index: 50;
      }

    .cslider-card:not(.active) {
        width: 280px; /* 나머지 카드 크기 */
        height: 360px; 
        opacity: 1; 
    }

    .cmobile-slider-container {
        margin-top: 100px;
  
    }

    .cslider-card {
        margin: auto;
    }

    .cslider-card img {
        width: 70vw;
    }
}
