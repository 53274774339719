body {
    background-color: black;
}

.exdcon {
    margin-left: 399px;
    background-color: rgb(0, 0, 0);
    width: 1440px;
    padding-top: 100px;
}

hr {
    width: 1312px;
    height: 1px;
    transform: translateX(-220px);
}

.exdtitle {
    color: #FFF;
    text-align: left;
    font-size: 32px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.352px;
}

.exddate {
    color: #ADADAD;
    font-family: "MyfontL";
    font-size: 15px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.165px;
    text-align: left;
}

.exdexplain {
    color: #FFF;
    text-align: left;
    font-size: 15px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.165px;
    margin-top: 49px;
   word-break: break-all;
    width: 800px;
}

.funtions {
    width: 150px;
    justify-content: space-between;
    display: flex;
    position: absolute;
    padding-left: 900px;
    transform: translateY(-23px);
}

.view, .share {
    color: #ADADAD;
    font-size: 15px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.165px;
}
.exdimg {
    position: relative;
    width: 100%; /* 부모 요소가 화면 너비에 맞춰지도록 설정 */
    max-width: 600px;
    height: auto;
    margin-top: 40px;
    overflow: hidden;
    text-align: center; /* 이미지를 중앙 정렬 */
}

.exdimg img {
    max-width: 100%; /* 부모 요소의 너비에 맞추도록 설정 */
    height: auto;
    display: block;
    object-fit: contain; /* 비율을 유지하고 잘리지 않도록 설정 */
}

.exdbottom {
    color: #FFF;
    text-align: left;
    font-size: 15px;
    font-family: "MyfontL";
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.165px;
    margin-top: 20px;width: 800px;
     word-break: break-all;
     padding-bottom: 240px;
}
@media (max-width: 767px) {
    .exdcon{
        margin: auto;
    }.exdimg{
         margin-left: 20px;
        width: 80vw;
    }.exdexplain{
        width: 90vw;
        font-size: 10px;
        word-break: break-all; /* 문자 단위 줄바꿈 설정 */
      
        padding-left: 10px;
    }.exdtitle{
        margin-left: 10px;
        font-size: 25px;
    }.exddate{
        font-size: 12px;
        margin-left: 10px;
        text-align: left;
    }.funtions{
        padding: 0;
        left: 5px;
        margin-top: 43px;
    }.exdbottom{
    
        margin-left: 10px;
        font-size: 10px;
        padding-bottom: 170px;
        width: 90vw;
        word-break: break-all;
    }
}
