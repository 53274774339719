.none{
    display: none;
    width: 10px;height: 10px;
    overflow: hidden;
}
.con {
    position: relative;
    text-align: center;
    background-color: rgb(0, 0, 0);
    width: 100vw; /* 이 값은 필요에 따라 조정 */
    margin: auto;

    top: -150px;
}

.topimg-container {
    position: relative;
}

.topimg {
    width: 100vw;
    height: 900px;
    object-fit: cover;
    position: relative;
    z-index: 1;    filter: blur(4px); 
}

.black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 1980px;
    height: 920px;
    background-color: black;
    opacity: 0.43;
    z-index: 2;
}

.imgtxt {
    color: #FFF;
    position: absolute;
    top: 50%; /* 세로 가운데 정렬 */
    left: 50%; /* 가로 가운데 정렬 */
    transform: translate(-50%, -50%); /* 가운데 정렬 보정 */
    font-size: 48px;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: -0.528px;
    z-index: 3; /* 오버레이 위에 위치 */
}

.description {
    position: relative;
    z-index: 3;
    padding: 150px;
    background-color: rgb(0, 0, 0);
    margin-left: 30px;
}
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 20px 0; /* 위 아래 여백만 남기고 좌우 여백 제거 */
width: 940px;
    margin: 0 auto; /* 가운데 정렬 */
}

.grid-image {
    width: 100%; /* 100%로 설정해 그리드에 맞게 조정 */
    height: auto;
    object-fit: cover;
    max-width: 100%; /* 이미지의 최대 너비를 100%로 설정 */
    display: block; /* 이미지가 줄 바꿈을 하지 않도록 설정 */
    overflow: hidden;
}

.txttitle{
    margin-top: 30px;
    color: #FFF;
text-align: left;
font-size: 36px;

font-weight: 800;
line-height: 150%; /* 54px */
letter-spacing: -0.396px;
margin-bottom: 20px;
}.txtexplain{
    color: #FFF;
    text-align: left;
font-size: 16px;

font-weight: 800;
line-height: 150%; /* 24px */
letter-spacing: -0.176px;
margin-bottom: 130px;
}.txtdetail{
    color: #FFF;
margin-top: 12px;
font-size: 16px;
text-align: left;
font-weight: 800;
line-height: 150%; /* 24px */
letter-spacing: -0.176px;
}
@media (max-width: 767px) {
    .description {

        padding: 0px;
    }.txttitle{
        font-size: 24px;
    }.txtexplain {
        font-size: 12px;
        word-break: keep-all;
        padding-right: 20px;
    
    }.txtdetail{
        font-size: 14px;
    }    .image-grid {
        grid-template-columns: repeat(3, 1fr); /* 3열 그리드 */
        gap: 5px;
        padding: 10px;
    }
    .grid-image {
        width: 100%;
        aspect-ratio: 1 / 1; /* 1:1 비율 유지 */
        object-fit: cover;
    }
    .image-grid{
        width: 90vw;
        margin-left: 0px;
    }
}