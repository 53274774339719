.exhibition-page {
  padding: 20px; 
  width: 1110px;
  background-color: black;
  margin-left: 450px;
  margin-top: 100px;
  padding-bottom: 100px;
}

.extitle {
  color: #FFF;
  font-size: 24px;
  text-align: left;
  font-weight: 800;
  line-height: 150%; /* 36px */
  letter-spacing: -0.264px;
}

.etab-menu {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.etab {
  margin-top: 20px;
  padding: 1px 20px;
  background-color: black;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 28px;
  color: white;
  width: 60px;
  height: 38px;
  transition: border-color 0.3s;
  font-size: 14px;
  font-weight: 800;
  display: flex; 
  justify-items: center;
  align-items: center;
  margin-bottom: 20px;
}

.etab.active, .etab:hover {
  border-color: white;
}

.exhibition-title {
  margin-bottom: 20px;
  font-size: 24px;
}

.exhibition-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 기본 4열 */
  gap: 20px;
}

.exhibition-item {
  text-align: center;
}

.exhibition-image {
  width: 100%; /* 화면에 맞게 줄어듭니다 */
  height: auto; /* 비율 유지 */
  aspect-ratio: 1; /* 비율 유지 (1:1) */
  object-fit: cover; /* 비율 유지 및 잘리기 */
}

.exhibition-info {
  margin-top: 10px;
}

h3 {
  color: white;
  font-size: 14px;
  text-align: left;
  font-weight: 800;
  line-height: 150%; /* 21px */
  letter-spacing: -0.154px;
}

p {
  color: #989898;
  font-family: 'MyfontL';
  font-size: 13px;
  text-align: left;
  font-weight: 800;
  line-height: 150%; /* 19.5px */
  letter-spacing: -0.143px;
}

/* 모바일 뷰 설정 */
@media (max-width: 767px) {
  .exhibition-page {

      margin: auto;
      margin-top: 100px;
      width: 100%; /* 가로 폭을 100%로 설정하여 화면에 맞게 */
      padding: 10px; /* 패딩 조정 */
      padding-bottom: 150px;
  }
  
  .exhibition-grid {
    margin-left: 10px;
      grid-template-columns: repeat(3, 1fr); /* 3열 그리드 */
      width: 88vw;
  }.extitle{
    font-size: 15px ;

    margin-left: 10px;
  }p{
    font-size: 10px;
  }.etab{
    width: 80px;
    text-align: center;
  align-items: center;justify-content: center;
  margin-left: 10px;
  }
}
