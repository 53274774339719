





.Maincon {
    padding-top: 50px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
}

.title {
    font-family: "MyfontB";
    justify-self: center;
    color: white;
    font-size: 64px;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: -0.704px;
    margin-top: 190px;
}

.down {
    margin-top: 140px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='12' viewBox='0 0 23 12' fill='none'><path d='M11.5 12C11.1688 12 10.8773 11.8896 10.6256 11.6564L0.357719 1.92638C0.11924 1.71779 -6.32877e-08 1.44785 -4.93429e-08 1.12883C-2.14534e-08 0.490797 0.516705 -9.82776e-07 1.20564 -9.52662e-07C1.55012 -9.37604e-07 1.84159 0.134968 2.06682 0.331287L11.5 9.2638L20.9332 0.331288C21.1584 0.134969 21.4631 -6.71786e-08 21.7944 -5.27004e-08C22.4833 -2.25859e-08 23 0.490798 23 1.12883C23 1.44785 22.8808 1.71779 22.6555 1.92638L12.3744 11.6564C12.1359 11.8896 11.8312 12 11.5 12Z' fill='white'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 23px 12px; /* 크기에 맞게 조정 */
    color: white; /* 글자 색상 */
    padding: 5px; /* 패딩 추가 (선택 사항) */
    text-align: center; /* 텍스트 중앙 정렬 */
    transform: translateY(130px);
}


.downcon {
    display: flex;
    justify-content: space-between;
    width: 942px;
    height: 338px;
    margin: auto;
 margin-top: 400px;
}

.titleimg {
    width: 304px;
    height: 304px;
    flex-shrink: 0;
    background-image: url("../../public/img/ma1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0px;
}
.mainlogo{
    background-image: url("../../public/img/top1.png");width: 72px;
    height: 23px;transform: translateX(-8px);
background-position: center;background-size: cover;background-repeat: no-repeat;
}

.contentcon {
    margin-left: 169px;
    width: 457px;
}

.upcontent {
    text-align: left;
    white-space: pre-wrap;
}

.mainbackimg {
    width: 99%;
    height: 1300px;
     /* 자식 요소의 절대 위치를 사용하기 위해 상대적으로 설정 */
    
    padding-top: 200px;
 transform: translateY(-60px);
    background: url("../../public/img/mainback3.jpg"); /* 배경 이미지 */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.bottomcontent {
    display: block; 
    text-align: left;
    white-space: pre-wrap; 
    font-size: 16px;
    font-family: 'MyfontL';
    font-weight: 500;
    line-height: 150%;
    color: white;
    margin-top: 36px;
}

.hili {
    font-size: 25px;
    font-family: 'MyfontB';
    font-weight: 500;
    line-height: 150%;
    color: white;
    margin: 0; /* 위와 아래 마진 제거 */
}

.nohili {
    font-size: 25px;
    font-weight: 500;
    line-height: 150%;
    color: white;
    margin: 0; /* 위와 아래 마진 제거 */
}

.midcon {
    width: 673px;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;

}

.midcontents {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.micontentscon {
    display: flex;
    justify-content: center; 
    flex-direction: column;
    margin-left: 33px;
}

.midimg {
    width: 92px;
    height: 92px;
    margin-left: 23px;
    border-radius: 50%; /* 둥근 모서리 */
    box-shadow: 0 0 0 3px black, 0 0 0 6px black; /* 윤곽선 */
}

.white {
    color: #FFF;
    text-align: left;
    font-size: 16px;
   max-width: 500px;
    font-weight: 600;     /* 단어가 너무 길 경우 줄바꿈 허용 */
    word-break: keep-all;  /* 비슷한 역할을 하는 다른 속성 */

}

.color {
    color: #ffffff;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}

.what {
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%; /* 30px */
    margin: auto;
    padding-bottom: 10px;
}
@media (max-width: 767px) {
    .title {
   
        font-size: 30px !important;
 margin: auto;

        word-break: break-word !important;
transform: translateY(50vh);
        word-break: keep-all !important;
        width: 90%; max-width: 390px;
    }.downcon{

  flex-direction: column;
  width: 95%; 
  margin: auto; margin-top: 100vh;
    }
    .titleimg{
        align-self: center;
    }
.updownccon{
   margin-left: -165px;
    margin-top: 70px;
}.mainbackimg{
    margin-top: -300px;
    height: 1800px;
}    .bottomcontent{
    word-break: break-all;
    overflow-wrap: break-word;
    font-size: 12px;
}.down{
 transform: translateY(70vh);
  margin-top: 10vh;

}

.nohili {
 font-size: 20px;
 word-break: break-all;
 overflow-wrap: break-word;
  
}.white{
  
    word-break: keep-all;  
    font-size: 10px; width: 80vw;
    height: 30px;margin-left: 8px;
}

.color{
font-size: 14px;
margin-left: 5px;
}
.midimg{
    width: 50px;height: 50px;
}.micontentscon{
    margin-left: 9px;
}.what{
 margin-left: 29vw;
}
}

