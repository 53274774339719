/* ImageSlider.css */

.sliderexplain{
    color: rgba(255, 255, 255, 0.80);

font-size: 24px;
text-align: left;
font-weight: 500;
line-height: 150%; /* 36px */
letter-spacing: -0.264px;
}.sliderarrow {
    width: 17px;         /* 고정 너비 */
    height: 30px;        /* 고정 높이 */
    flex-shrink: 0;      /* Flexbox에서 크기 축소 방지 */
    cursor: pointer; 
    color: #FFF;
    margin-left: -200px;
    overflow: hidden;
}

.slidernames{
    color: #FFF;
text-align: left;
font-family: "MyfontB";
font-size: 40px;
cursor: pointer;
font-weight: 700;
line-height: 150%; /* 60px */
letter-spacing: -0.44px;
}.flexflexslide{ width: 500px;
    display: flex; justify-content: space-between;
    align-items: center;
}
.sliderexcon{
    margin-left: 600px;
margin-top: 100px;
}
.slider-container {

    display: flex;

    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 600px;
    margin-left: -67px;
    width: calc(365px * 3 + 416px + 50px * 2); /* 카드 5개 (중앙 카드 416px, 나머지 4개 365px) + 간격 4개(50px*4) */
}

.slider {
    display: flex;
    transition: transform 0.6s ease;
    margin-left: 2605px;
    align-items: center;
    
}

.slider-card {
    flex-shrink: 0;
    margin: 0 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    margin: 0 5 px; 

    opacity: 0; /* 기본적으로 카드 숨기기 */
    transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease, transform 0.6s ease; /* 크기, 불투명도, 변형 애니메이션 설정 */
}


.slider-card.active {
    width: 416px; /* 중앙 카드 크기 고정 */
    height: 520px;
    opacity: 1; /* 활성화된 카드 보이기 */
    transform: scale(1.0); 
}

/* 이전 및 다음 카드 스타일 */
.slider-card:not(.active) {
    width: 365px; /* 나머지 카드 크기 */
    height: 455px; opacity: 1; 

}.slider-card.prev {
    transform: translateX(-50px); /* 왼쪽으로 이동 */
}

.slider-card.next {
    transform: translateX(50px); /* 오른쪽으로 이동 */
}
.slider-card img {
    width: 100%;
    height: 100%;
    object-fit: contain; 

}
.slider-button {
    position: absolute;

    transform: translateY(250px );
    width: 50px;
    height: 50px;
    background-color: white; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1; /* 슬라이더 카드보다 위에 위치하게 설정 */
}
.empty-card {
    width: 100%; /* 카드 크기 설정 */
    height: 100%; /* 카드 크기 설정 */

}
.slider-button.left {
    margin-left: -750px;
}

.slider-button.right {
    right: 30px; /* 오른쪽 끝에 위치 */
}

.slider-button svg {
    width: 20px;
    height: 20px;
    fill: black;
}

@media (max-width: 767px) {
.sliderexcon{
    margin: auto;

}.slider {
    margin-left: 30vw;
    padding: 0 0px; 
}
.slider-card.active {

    transform: scale(0.3); 
}
.slider-button.left {
   margin-left: 23px;width: 20px;height: 20px;
    transform: translateY(-200px);
}.slider-button.right {
   transform: translateY(-200px);width: 20px;height: 20px;
   right: 23px;
}.slider-card:not(.active) {
    width: 280px; /* 나머지 카드 크기 */
    height: 360px; opacity: 1; 

}.mobile-slider-container{
    margin-top: 100px; 
    margin-left: 0;
}

.slider-card{
    margin: auto;
    
}.slider-card img{
    width: 70vw;
}.sliderexplain{
    font-size: 18px;
    margin-left: 10px;
}.slidernames{
    font-size: 22px;    margin-left: 10px;
    padding-top: 52px; margin-bottom: 5px;
}.sliderarrow{
    margin-top: 40px;
 height: 16px;width: 10px;
}.flexflexslide{
width: 80vw;height: 70px;
}
}