.navcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 63px 13px 42px;
  background-color: transparent;
  top: 0;
  position: fixed;
  width: calc(100vw - 100px);
  z-index: 100;
}

.pinkcon {
  display: flex;
  gap: 20px;
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
  margin-top: -20px;
}

.nav-link {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  margin-top: 15px;
}

.nav-link:hover {
  color: #ff69b4;
}

.toptopimg {
  width: 97px;
  height: 31px;
  background-image: url("../../public/img/top1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  transform: translateX(-100px);
}

.topright {
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  background-image: url("../../public/img/white.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;height: 30px;
}

.hamburger {
  position: absolute; /* 위치를 절대 위치로 설정 */
  right: 50px; /* 오른쪽에 위치 */
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
  z-index: 150;
  transition: transform 0.5s ease;
  top: 30px; padding-left: 50px ;
}

.imgres{
  width: 97px;
  height: 31px;
  margin: auto;
}

.hamburger.open div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px); /* X자 형태로 회전 */
}

.hamburger.open div:nth-child(2) {
  opacity: 0; /* 가운데 막대 숨기기 */
}

.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px); /* X자 형태로 회전 */
}


.overlay {
  position: fixed;
  top: 0;
  margin-left: 100vw; /* 화면 오른쪽으로 이동 */
  width: 200px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  transition: transform 0.3s ease-in-out;
  z-index: 140;
  display: none;
  align-items: center;
  justify-content: center;
}

.overlay.open {
  transform: translateX(calc(100vw - 200px)); /* 오른쪽으로 200px 이동 */
}

.overlay .pinkcon {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

  
  /* 모바일 상태에서 스타일 */
@media (max-width: 767px) {
  .topright {
    margin-left: -22px;
     margin-top: -41px;
     
     
  }
 .navcon{
  display: block;
 }

  .toptopimg {

width: 120px;height: 40px;
   display: block;
      transform: none; /* 기존 transform 제거 */
 margin: auto !important;
      transform: translateY(3px);
   
  }
  .pinkcon{
    margin-left: -20px;
   margin-top: -13px;    display: none; /* 기본적으로 숨김 */
   padding-top: 40px;
  }
  .hamburger {
   display: flex;
transform: translateX(5vw);
}

.overlay {
  position: fixed;
  padding-top: 100px;
  
  margin-left: 200px; /* 오른쪽으로 이동 */
  width: 300px; /* 너비 설정 */
  height: 100vh; /* 전체 높이 */
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateX(100vw); /* 처음에는 오른쪽 바깥으로 숨김 */
  transition: transform 0.5s ease-in-out; /* 부드러운 애니메이션 */
  z-index: 140; /* 햄버거 버튼보다 낮게 설정 */
  display: flex;
  align-items:start;

}

.overlay.open {
  display: flex; /* 열릴 때 표시 */
  transform: translateX(calc(100vw - 300px)); /* 100vw에서 150px 빼고 이동 */
}

.overlay .pinkcon {
  display: flex;
  flex-direction: column;
  gap: 20px;
 top: 10px;
}
.hamburger.open{
  transform: translateX(-250px);
}
.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;

}


}
