/* Projectpage.css */
.procon {
    background-color: #000000;
    margin-left: 280px;
    margin-top: 130px;
    padding-bottom: 130px;
    padding: 20px;
}

.image-background {
   width: 268px;height: 268px;
  
 
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.tab {
    padding-left: 20px;padding-right: 20px;
    margin-left: 20px;
    background-color: black;
    border: 2px solid black;
    border-radius: 28px;
    color: white;
    cursor: pointer;
    height: 38px;
    transition: border-color 0.3s;font-size: 14px;
    font-style: normal;
    font-weight: 800;
    display: flex; justify-items: center;align-items: center;
}

.tab:hover, .tab.active {
    border-color: white; /* 호버 또는 활성화 시 테두리 색상 흰색 */
    background-color: #000000; /* 호버 시 배경색 변경 */
    height: 38px;
}

.tab.active {
     height: 38px;
}

.overlay {  position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 5px; margin-left: 20px;
}

.project-details {
    margin-top: 5px; /* 이미지와 내용 사이의 간격 */
    text-align: left;color: #FFF;
    
}

.tab-menu {
    display: flex;
    margin-top: 10px;
    margin-left: -20px;
    padding-bottom: 20px;
}


.expl {
    color: #FFF;
    margin: auto;
    font-size: 24px;
    text-align: left; /* 왼쪽 정렬 */
    font-weight: 800;
    line-height: 150%; /* 36px */
    letter-spacing: -0.264px;

}
.category-title {
    color: #FFF; /* 텍스트 색상 */
    font-size: 32px; /* 폰트 크기 */
 
    font-weight: 800; /* 폰트 두께 */
    line-height: 150%; /* 줄 높이 */

    letter-spacing: -0.352px; /* 글자 간격 */

    transform: translateX(-30px); margin-left: 30px;
   /* 고정된 너비 */
    white-space: normal; /* 텍스트가 넘칠 경우 줄바꿈 */


}
 .concon{
    display: flex;
    align-content: space-between;
    height: 500px;
 }

.proti{
    color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 150%; /* 21px */
letter-spacing: -0.154px;
}.prodate{
    color: #989898;

font-size: 13px;

font-weight: 800;
line-height: 150%; /* 19.5px */
letter-spacing: -0.143px;
}/* 그리드 레이아웃 */
.project-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 기본적으로 4개 열 */
    grid-column-gap: 20px ; /* 열 간격 설정 */
    grid-row-gap: 10px; /* 행 간격 설정 */
    width: 100%; /* 그리드 전체 너비를 100%로 설정 */
    max-width: 1090px; /* 최대 너비 설정 (필요에 따라 조정) */
   
}
@media (max-width: 767px) {
    .slider-container-mobile{
 margin-left:-10px;        width: 90vw;height: 100%;
    }
.procon{
    height: auto; /* 높이를 자동으로 설정 */
    width: 88vw; /* 전체 너비 사용 */
    padding: 0; /* 패딩 제거 */
    margin: auto; /* 중앙 정렬 */
}.image-background{
    width: 100%;   height: 170px;
    aspect-ratio: 1 / 1; /* 1:1 비율 유지 */
    object-fit: cover;
}.expl{
    font-size: 15px;
    margin-top: 100px;

    margin-left: 0px;
}.tab{
    word-break: keep-all;

    font-size: 12px;
    height: 32px;
    text-align:center;
}  .category-title {
    display: none !important;
  }.tab.active,.tab:hover{
 
    font-size: 12px;
    height: 32px;
    text-align:center;
}  .project-grid {
    grid-template-columns: repeat(2, 1fr); /* 모바일에서 2개 열 */
    gap: 5px;   max-width: 88vw; 
    height: 100%;
    margin-left: 0px;
}.tab-menu{
    margin-bottom: 20px;
}

}
@media (max-width: 460px) {
    .tab,.tab.active,.tab:hover {
        font-size: 9.5px; /* 화면 너비가 480px 이하일 때 글자 크기 줄이기 */
        word-break: keep-all;
    }
}