@font-face {
    font-family: 'Myfont';
    src: url('../../public/fonts/GmarketSansTTFMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MyfontB';
    src: url('../../public/fonts/GmarketSansTTFBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MyfontL';
    src: url('../../public/fonts/GmarketSansTTFLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
body{
    font-family: 'Myfont',sans-serif;
}

p{
    font-family: 'Myfont',sans-serif;
}
h1{
    font-family: 'Myfont',sans-serif;
}